import {
	bodyFontFamily,
	colors,
	Flex,
	Icon,
	scale,
	Text,
	ProductCardText
} from "@focus-nordic/ui-components"
import React from "react"
import styled from "styled-components"
import { LocalesContext } from "../../context"
import { translationNamespace, useCart, useLocales } from "../../hooks"
import { formatToCurrency } from "../../utils/formatters"
import debounce from "lodash/debounce"
import { CartItem } from "../../@types/graphql.generated"

interface CustomPriceProps {
	cartItem: CartItem
}

const StyledInput = styled.input`
	width: 100%;
	font-size: ${scale.px(1.75)};
	font-family: ${bodyFontFamily};
	padding: ${scale.px(0.2)} ${scale.px(0.4)};
	border: solid ${colors["grey-3"]} 1px;
	border-radius: ${scale.px(0.5)};
	color: ${colors["grey-5"]};
	outline: none;
	* {
		transition: all 0.3s;
	}
	&:focus {
		border-color: ${colors["blue-3"]};
	}
	/* override any browser specific or plugin pre fill styles */
	&[style] {
		background: ${colors.white} !important;
	}
`

export const CustomPrice: React.FC<CustomPriceProps> = props => {
	const { updateCartItemCustomPrice, cart, loading } = useCart()
	const { localeCode } = React.useContext(LocalesContext)
	const productLocales = useLocales(translationNamespace.shared("Product"), [
		"price"
	])

	const debouncedUpdateCartItemCustomPrice = React.useRef(
		debounce(updateCartItemCustomPrice!, 350)
	).current

	// check if product exist in cart
	const _cartItem = cart?.items?.find(
		item =>
			item.product?.articleNumber === props.cartItem.product?.articleNumber
	)

	// set initial price value to value from cart
	const [price, setPrice] = React.useState(String(_cartItem?.price?.netPrice))

	// Update price state if the cache is updated
    React.useEffect(() => {
		if (_cartItem?.price) {
			setPrice(() => String(_cartItem.price?.netPrice))
		}
	}, [_cartItem])

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let { value } = e.target
		if (Number(value) < 1) value = String(_cartItem?.product?.price?.netPrice)
		value = value.replace(/-/g, "")
		setPrice(value)
		handleUpdate(value)
	}

	const handleUpdate = (value?: string) => {
		if (!_cartItem?.product) return
		if (!value) setPrice(String(_cartItem?.product?.price?.netPrice))

		// only provide price if there is a value and that value is not equal to product.price
		debouncedUpdateCartItemCustomPrice({
			product: _cartItem.product,
			...(value &&
				value !== String(_cartItem?.product?.price?.netPrice) && {
					customPrice: Number(value)
				})
		})
	}

	return (
		<Flex flexDirection="column" flexWrap="wrap">
			<Flex zIndex={2}>
				<ProductCardText.Label
					mb={0.25}
					ml={3}
					fontWeight="bold"
					textColor="grey-4"
					whiteSpace="nowrap"
				>
					{productLocales.price}
				</ProductCardText.Label>
			</Flex>
			<Flex zIndex={2}>
				<Flex flexShrink={0} mr={1}>
					<button onClick={() => handleUpdate()}>
						<Icon icon="reset" size={2} />
					</button>
				</Flex>
				<Flex minw={12}>
					<StyledInput
						type="number"
						value={price}
						onChange={handleChange}
						disabled={loading}
						min={1}
					/>
					<Text mb={0} ml={0.5}>
						{/* show the right currency symbol based on priceInfo and locale code */}
						{formatToCurrency(
							_cartItem?.price ?? {
								currency: "",
								netPrice: 0
							},
							localeCode
						)}
					</Text>
				</Flex>
			</Flex>
		</Flex>
	)
}
