import * as React from "react"
import { Link } from "react-router-dom"
import { Flex, Text, TextProps } from "@focus-nordic/ui-components"
import { ProductOverviewBlock as IProductOverviewBlock } from "../../@types/graphql.generated"
import { useLocales, translationNamespace } from "../../hooks/useLocales"
import { formatPercentage, formatPriceInfo } from "../../utils/formatters"
import { LocalesContext } from "../../context/LocalesContext"
import { StockStatusType } from "../StockStatus"
import { PRODUCT_ANCHOR_LIST_HEIGHT } from "../../constants"
import { Discount, DiscountData } from "@focus-nordic/ui-components/dist/components/ProductCard/Text"
import { formatDate } from "../../utils/dateHelpers"

interface ProductDataProps extends TextProps {
	title?: React.ReactNode
	data?: React.ReactNode
	discountData?: DiscountData
}

const DataTitle: React.FC<TextProps> = props => (
	<Text
		fontWeight="bold"
		fontSize={1.75}
		textColor="black"
		mb={{ _: 1, m: 0.5 }}
		whiteSpace="nowrap"
		{...props}
	/>
)

const DataDescription: React.FC<TextProps> = props => (
	<Text fontSize={1.75} mb={{ _: 1, m: 0.5 }} whiteSpace="nowrap" {...props} />
)

const ProductData: React.FC<ProductDataProps> = ({ title, data, discountData, ...rest }) => (
	<Flex as="span" flexDirection="row" alignItems="baseline">
		{title && (
			<DataTitle as="dt" mr={1} {...rest}>
				{title}
			</DataTitle>
		)}
		{data && (
			<DataDescription as="dd" mr={3} {...rest}>
				{data}
			</DataDescription>
		)}
		{discountData && (
			<Discount data={discountData} mr={3} flexDirection="row" />
		)}
	</Flex>
)

const ProductMainInfo: React.FC<IProductOverviewBlock> = props => {
	const {
		articleNumber,
		vendorArticleNumber,
		brandName,
		brand,
		buyable,
		name,
		ean,
		fee,
		margin,
		price,
		rsp,
		description,
		discount,
		last30DaysLowestPrice
	} = props.product

	const { readMoreAnchor } = props

	const locales = useLocales(translationNamespace.shared("Product"), [
		"articleNumber",
		"vendorArticleNumber",
		"ean",
		"fee",
		"margin",
		"rsp",
		"campaignMessage",
		"lowestPriceMessage"
	])
	const sharedLocales = useLocales(translationNamespace.shared("Common"), [
		"readMore"
	])

	const { localeCode } = React.useContext(LocalesContext)

	return (
		<Flex flexDirection="column" mb={2}>
			{brand && (
				<Flex mb={{ _: 2, l: 3 }} w={{ _: 9, l: 14 }}>
					<Link to={brand.link.url}>
						<img
							src={brand.logosrc}
							alt={brand.name}
							style={{ display: "block", width: "100%" }}
						/>
					</Link>
				</Flex>
			)}
			{brandName && (
				<Text
					fontSize={1.5}
					textTransform="uppercase"
					fontWeight="bold"
					mb={{ _: 0.5, l: 0 }}
				>
					{brandName}
				</Text>
			)}
			{name && (
				<Text as="h1" fontSize={3} textColor="black" fontWeight="bold" mb={3}>
					{name}
				</Text>
			)}
			{(description || readMoreAnchor) && (
				<Flex maxw={80} mr={{ _: 3, l: 0 }} mb={3} flexDirection="column">
					{description && (
						<Text truncate={{ _: "singleLine", s: "none" }} mb={{ _: 1, l: 0 }}>
							{description}
						</Text>
					)}
					{readMoreAnchor && (
						<Text.Anchor
							href={readMoreAnchor.url}
							mb={0}
							textColor="blue-6"
							data-target-offset={PRODUCT_ANCHOR_LIST_HEIGHT}
							style={{ textDecoration: "underline" }}
						>
							{readMoreAnchor.title || sharedLocales.readMore}
						</Text.Anchor>
					)}
				</Flex>
			)}
			<Flex as="dl" flexWrap="wrap" mb={2}>
				{articleNumber && (
					<ProductData title={locales.articleNumber} data={articleNumber} />
				)}
				{vendorArticleNumber && (
					<ProductData
						title={locales.vendorArticleNumber}
						data={vendorArticleNumber}
					/>
				)}
				{ean && <ProductData title={locales.ean} data={ean} />}
				{fee && (
					<ProductData
						title={locales.fee}
						data={formatPriceInfo(fee, localeCode)}
					/>
				)}
				{discount && rsp ? (
					<ProductData
						title={locales.rsp}
						discountData={{
							discountPrice: formatPriceInfo(discount.discountedPrice, localeCode),
							comparisonPrice: formatPriceInfo(discount.comparisonPrice, localeCode),
							startDate: formatDate(discount.startDate),
							endDate: formatDate(discount.endDate),
							tooltipId: articleNumber,
							locales: {
								campaignMessage: locales.campaignMessage
							}
						}}
					/>
				) : rsp && (
					<ProductData
						title={locales.rsp}
						data={formatPriceInfo(rsp, localeCode)}
					/>
				)}
				{typeof margin !== "undefined" && margin !== null && (
					<ProductData title={locales.margin} data={formatPercentage(margin)} />
				)}
			</Flex>
			<Flex flexWrap="wrap" mb={1}>
				<StockStatusType
					id={props.product.id}
					stockStatus={props.product.stockStatus}
					onDemand={props.product.onDemand}
					buyable={props.product.buyable}
					textComponent={props => <DataDescription mb={0} {...props} />}
				/>
			</Flex>
			{discount && price && !rsp ? (
				<Flex flexDirection="column">
					<Flex alignItems="baseline">
						<Text variant="headline-4" fontWeight="normal" textColor="red-2" mr={2} mb={0.5}>
							{formatPriceInfo(discount.discountedPrice, localeCode)}
						</Text>
						<Text variant="headline-5" fontWeight="normal" textDecoration="line-through" textColor="grey-3" mb={0.5}>
							{formatPriceInfo(discount.comparisonPrice, localeCode)}
						</Text>
					</Flex> 
					{last30DaysLowestPrice && (
						<Text mb={0} fontSize={1.75}>
							{locales.lowestPriceMessage}: {formatPriceInfo(last30DaysLowestPrice, localeCode)}
						</Text>
					)}
				</Flex>
			) : (
				price && (
					<Text variant="headline-4" fontWeight="normal" textColor={buyable === "DISABLED" ? "grey-3" : "black"}>
						{formatPriceInfo(price, localeCode)}
					</Text>
				)
			)}
		</Flex>
	)
}

export { ProductMainInfo }
