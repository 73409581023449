import { ProductCardCartItemProps } from "@focus-nordic/ui-components"
import {
	CartItem as ICartItem,
	CustomerTypeEnum
} from "../../@types/graphql.generated"
import { productDataToProductCardProps, formatPriceInfo } from "../../utils"
import { formatPercentage } from "../../utils/formatters"
import { AllCartItemFieldsFragment } from "../../operations/cart.generated"
import TagManager from "react-gtm-module"

export const cartItemDataToCartItemProps = (
	cartItem: ICartItem,
	localeCode: string,
	currency: string
): Omit<ProductCardCartItemProps, "locales"> => {
	const { product } = cartItem
	const quantityPrice = cartItemQuantityPrice(cartItem)
	const cartItemDiscountPriceTotal = calculateCartItemDiscountedPrice(cartItem, false)
	const cartItemComparisonPriceTotal = calculateCartItemDiscountedPrice(cartItem, true)
	const productDiscountPriceTotal = calculateProductDiscountedPrice(cartItem, false)
	const productComparisonPriceTotal = calculateProductDiscountedPrice(cartItem, true)

	return {
		to: "",
		...(product && productDataToProductCardProps(product, localeCode)),
		...(cartItem.quantity !== undefined && {
			quantity: cartItem.quantity
		}),
		...(cartItem.rsp && {
			rsp: formatPriceInfo(cartItem.rsp, localeCode)
		}),
		...(cartItem.margin && {
			margin: formatPercentage(cartItem.margin)
		}),
		...(cartItem.product?.fee && {
			fee: formatPriceInfo(cartItem.product.fee, localeCode)
		}),
		...(cartItem.price && {
			price: formatPriceInfo(cartItem.price, localeCode)
		}),
		...(quantityPrice && {
			priceTotal: formatPriceInfo(
				{
					netPrice: quantityPrice,
					currency
				},
				localeCode
			)
		}),
		...(cartItemDiscountPriceTotal && {
			cartItemDiscountedPriceTotal: formatPriceInfo(
				{
					netPrice: cartItemDiscountPriceTotal,
					currency
				},
				localeCode
			)
		}),
		...(cartItemComparisonPriceTotal && {
			cartItemComparisonPriceTotal: formatPriceInfo(
				{
					netPrice: cartItemComparisonPriceTotal,
					currency
				},
				localeCode
			)
		}),
		...(productDiscountPriceTotal && {
			productDiscountedPriceTotal: formatPriceInfo(
				{
					netPrice: productDiscountPriceTotal,
					currency
				},
				localeCode
			)
		}),
		...(productComparisonPriceTotal && {
			productComparisonPriceTotal: formatPriceInfo(
				{
					netPrice: productComparisonPriceTotal,
					currency
				},
				localeCode
			)
		}),
		...(cartItem.discount && {
			cartItemDiscountedPrice: formatPriceInfo(cartItem.discount.discountedPrice, localeCode),
			cartItemComparisonPrice: formatPriceInfo(cartItem.discount.comparisonPrice, localeCode)
		})
	}
}

interface CartItem extends AllCartItemFieldsFragment {}

export const netPriceSum = (cartItems: CartItem[]) =>
	cartItems?.reduce(
		(acc, item) =>
			item.quantity *
				((item.price?.netPrice ?? 0) + (item.product?.fee?.netPrice ?? 0)) +
			acc,
		0
	)

export const cartTotalPrice = (
	cartItems: CartItem[],
	currency: string,
	localeCode: string
) => {
	const netPrice = netPriceSum(cartItems)

	return formatPriceInfo({ netPrice, currency }, localeCode)
}

export const cartItemQuantityPrice = (cartItem: CartItem) =>
	cartItem.price &&
	(cartItem.price.netPrice + (cartItem.product?.fee?.netPrice ?? 0)) *
		cartItem.quantity

export const calculateCartItemDiscountedPrice = (cartItem: CartItem, isComparisonPrice: boolean): number => {
	if (cartItem?.discount) {
		const discountedPrice = isComparisonPrice ?
			cartItem.discount.comparisonPrice.netPrice :
			cartItem.discount.discountedPrice.netPrice

		return discountedPrice * cartItem.quantity
	}

	return 0
}

export const calculateProductDiscountedPrice = (cartItem: CartItem, isComparisonPrice: boolean): number => {
	if (cartItem.product?.discount) {
		const { discountedPrice, comparisonPrice } = cartItem.product.discount
		const productDiscountedPrice = isComparisonPrice ?
			comparisonPrice.netPrice :
			discountedPrice.netPrice
		
		return (productDiscountedPrice + (cartItem.product?.fee?.netPrice ?? 0)) * cartItem.quantity
	}

	return 0
}

export const trackGoToCheckout = (
	pathName: string,
	productCount: number,
	userType: CustomerTypeEnum
) => {
	TagManager.dataLayer({
		dataLayerName: "PageDataLayer",
		dataLayer: {
			event: "checkOutButton",
			page: pathName,
			numberOfProducts: productCount,
			type: userType
		}
	})
}