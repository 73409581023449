import React from "react"
import {
	Flex,
	Text,
	Icon,
	Button,
	PrimaryMenuContext
} from "@focus-nordic/ui-components"
import { useFeatureFlags, useIsLoggedIn, useLocales } from "../../hooks"
import { translationNamespace } from "../../hooks/useLocales"
import { useCart } from "../../hooks/useCart"
import { Summary } from "./Summary"
import { ProductList } from "./ProductList"
import { CartSummary } from "../CartSummary"
import { CHECKOUT_ROUTE } from "@focus-nordic/www-common/constants"
import { COOKIE_ACTIVATED_DISCOUNT_CODE } from "../../constants"
import { Loader } from "../Loader"
import { MY_PAGES_ROUTES } from "../MyPagesPage/routes"
import { trackGoToCheckout } from "./utils"
import { useLocation } from "react-router"
import { CustomerTypeEnum } from "../../@types/graphql.generated"
import { DiscountCode } from "../DiscountCode"
import Cookies from "js-cookie"

interface MiniCartProps {
	containerDropdownId?: string
}

const MiniCart: React.FC<MiniCartProps> = props => {
	const isLoggedIn = useIsLoggedIn()
	const { cart, loading, initCart, isInitialized, checkItemsAvailability } = useCart()
	const { activeDropDownIds, closeAllDropDowns } = React.useContext(PrimaryMenuContext)
	const { features } = useFeatureFlags()
	const location = useLocation()

	React.useEffect(() => {
		initCart(isLoggedIn ? CustomerTypeEnum.B2B : CustomerTypeEnum.B2C)
	}, [])

	React.useEffect(() => {
		if (isInitialized && activeDropDownIds?.length > 0 && props.containerDropdownId && activeDropDownIds.includes(props.containerDropdownId)) {
			if (cart.items && cart.items.length > 0) {
				// Check if there are any cart item with quantity exceeds availableItemsCount
				const exceedsAvailableItems = cart.items.some(
					item => item.product?.availableItemsCount !== undefined && 
					item.quantity > item.product?.availableItemsCount
				)

				if (exceedsAvailableItems && checkItemsAvailability) {
					checkItemsAvailability()
				}
			}
		}
	}, [activeDropDownIds, props.containerDropdownId, isInitialized])

	React.useEffect(() => {
		if (cart.items?.length === 0) {
			removeDiscountCode()
		}
	}, [cart])

	const locales = useLocales(translationNamespace.shared("Cart"), [
		"cartTitle",
		"cartIsEmpty",
		"checkOut",
		"checkOutUrl"
	])

	const handleTracking = () => {
		trackGoToCheckout(
			location.pathname,
			cart.items?.length ?? 0,
			isLoggedIn ? CustomerTypeEnum.B2B : CustomerTypeEnum.B2C
		)
	}

	const removeDiscountCode = () => {
		Cookies.remove(COOKIE_ACTIVATED_DISCOUNT_CODE)
	}

	const zeroCartItemsPrice = cart.itemsPriceSum?.netPrice === 0

	return (
		<>
			{loading && !isInitialized ? (
				<Loader minh={40} />
			) : (
				<Flex flexDirection="column" flex={1}>
					{Boolean(cart.items?.length) ? (
						<>
							<Text variant="headline-4">{locales.cartTitle}</Text>
							<Flex
								flex={1}
								display={{ _: "block", l: "flex" }}
								flexWrap="wrap"
								flexDirection={{ _: "column", l: "row" }}
								alignItems="flex-start"
								justifyContent="flex-end"
							>
								<Flex
									flexDirection="column"
									flex={{ _: 1, l: 2 }}
									w={{ _: 1, l: "auto" }}
								>
									<ProductList />
								</Flex>
								<Flex
									flexDirection="column"
									flex={1}
									pt={2}
									ml={{ _: 0, l: 4.5 }}
									minw={{ _: 1, s: 38 }}
									maxw={{ _: 1, s: 38 }}
								>
									{!isLoggedIn && <DiscountCode />}
									<CartSummary>
										<Summary />
										{isLoggedIn ? (
											features.isMyPagesCheckoutEnabled ? (
												<Button.Link
													mt={3}
													to={MY_PAGES_ROUTES.checkout}
													variant="green"
													disabled={zeroCartItemsPrice}
													onClick={() => {
														closeAllDropDowns()
														handleTracking()
													}}
												>
													{locales.checkOut}
												</Button.Link>
											) : (
												<Button.Anchor
													href={locales.checkOutUrl}
													variant="green"
													disabled={zeroCartItemsPrice}
													onClick={() => {
														closeAllDropDowns()
														handleTracking()
													}}
												>
													{locales.checkOut}
												</Button.Anchor>
											)
										) : (
											<Button.Link
												mt={3}
												to={CHECKOUT_ROUTE}
												variant="green"
												disabled={zeroCartItemsPrice}
												onClick={() => {
													closeAllDropDowns()
													handleTracking()
												}}
											>
												{locales.checkOut}
											</Button.Link>
										)}
									</CartSummary>
								</Flex>
							</Flex>
						</>
					) : (
						<Flex
							flex={1}
							justifyContent="center"
							alignItems="center"
							minh={40}
							w={{ _: 1, l: "auto" }}
						>
							<Flex flexDirection="column" alignItems="center">
								<Icon icon="cart" color="grey-3" size={20} />
								<Text>{locales.cartIsEmpty}</Text>
							</Flex>
						</Flex>
					)}
				</Flex>
			)}
		</>
	)
}

export { MiniCart }
